

.tapir-color { color: #8e1327; }
.bg-tapir { background-color: #8e1327!important; }

.btn-tapir {
    color: #fff;
    background-color: #8e1327;
    border-color: #8e1327;
}

.btn-tapir:hover {
    color: #fff;
    background-color: #8e1327;
    border-color: #8e1327;
}

/*.table.js-dataTable-full { width: auto; }*/